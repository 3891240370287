.search-by-manufacturer {
  padding: 0px 10px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.section-heading {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
}

.manufacturer-list {
  margin-top: 0px;
}

.item-text {
  min-width: 30px !important;
  text-align: center !important;
  font-size: 16px !important;
  background-color: #f8f9fa !important;
  border-radius: 8px !important;
  transition: all 0.3s ease-in-out !important;
  cursor: pointer !important;
  color: #555 !important;
  padding: 3px 5px !important;
  margin: 5px !important;
}

.item-text:hover {
  background-color: #000;
  color: white;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Dropdown styling */
.manufacturer-dropdown {
  position: absolute;
  background-color: #f8f9fa;
  color: #333;
  z-index: 2; /* <-- High z-index, no !important needed */
  text-align: left;
  font-size: 13px;
  padding: 10px 0px 10px 10px;
  width: 240px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  left: 100%;
  top: 0;
}

.manufacturer-dropdown a {
  text-decoration: none;
  border-bottom: 1px solid white;
  padding: 3px 10px;
}

.manufacturer-dropdown a:hover {
  background-color: #000;
  width: 240px;
  font-size: 13px;
  transition: 0.2s;
  color: white;
  border-radius: 5px;
}

.subcategory-dropdown {
  position: absolute;
  background-color: #f8f9fa;
  z-index: 3;
  text-align: left;
  font-size: 13px;
  padding: 5px;
  left: 100%;
  margin-top: -2rem;
  width: 240px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.subcategory-dropdown a {
  text-decoration: none;
  padding: 3px 10px;
}

.subcategory-dropdown a:hover {
  background-color: #000;
  width: 240px;
  font-size: 13px;
  transition: 0.2s;
  color: white;
  border-radius: 5px;
}

.sub-subcategory-dropdown {
  position: absolute;
  left: 100%;
  margin-top: -2rem;
  background-color: #f8f9fa;
  z-index: 4;
  text-align: left;
  font-size: 13px;
  padding: 5px;
  width: 240px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.sub-subcategory-dropdown a {
  text-decoration: none;
  padding: 3px 10px;
}

.sub-subcategory-dropdown a:hover {
  background-color: #000;
  transition: 0.4s;
  color: white;
  font-size: 13px;
  border-radius: 5px;
}
