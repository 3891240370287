.loginButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.loginButton button {
  display: inline-block;
  border-radius: 7px;
  border: none;
  background: #1875ff;
  color: white;
  font-family: inherit;
  text-align: center;
  font-size: 13px;
  box-shadow: 0px 14px 56px -11px #1875ff;
  min-width: 10em;
  padding: 1em;
  transition: all 0.4s;
  cursor: pointer;
}

.loginButton button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
}

.loginButton button:hover span {
  background-color: #2478f6;
}

.loginButton button:hover span:after {
  opacity: 4;
  right: 0;
}
