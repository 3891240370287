body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.btn {
  border-radius: 0px !important;
}

.bg-danger {
  background: #eb3223 !important;
}

.bg-success {
  background: #0f7c41 !important;
}

.bg-dark {
  background: #000 !important;
}

.btn-dark {
  background: #000 !important;
}

.btn-success {
  background: #0f7c41 !important;
}

.menu-title {
  color: #000 !important;
}

.app-search .form-control,
.app-search .form-control:focus {
  border: 1px solid #000;
}

.main-menu-inner .menu-body .nav-link {
  color: #000 !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
}

.title-text,
.header-title {
  color: #000;
}

.p-inputtext {
  color: #000;
  border: 1px solid #000;
  width: 20vw;
}
